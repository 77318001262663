<template>
  <div class="row">
    <Card class="col-8">
      <template #header>
        <div
          class="d-flex align-items-center justify-content-between"
          style="width: 100%"
        >
          <div>
            <h4>Firma Kartı</h4>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-4">
          <b-form-group class="add-style">
            <label  for="vergiNo"
              >Vergi Kimlik No
              
            </label>
            <b-form-input
              type="text"
              id="vergiNo"
              required
              value="9971821926"
            />
          </b-form-group>
        </div>
        <div class="col-8 ">
          <b-form-group class="add-style">
            <label  for="unvan"
              >Ünvan
              
            </label>
            <b-form-input
              type="text"
              id="unvan"
              required
              value="ZE BİLGİ TEKNOLOJİLERİ PAZARLAMA VE DANIŞMANLIK ANONİM ŞİRKETİ"
            />
          </b-form-group>
        </div>
        <div class="col-12 mt-4">
          <div class="form-group">
            <label  for="yetkiBelgeNo"
              >Sınıfı
              
            </label>
            <select class="form-control" id="exampleFormControlSelect1">
              <option>Sahibinden</option>
              <option>Emlak Ofisi</option>
              <option>Banka</option>
              <option>İnşaat Firması</option>
              <option selected>Diğer</option>
            </select>
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="form-group">
            <label  for="yetkiBelgeNo"
              >Taşınmaz Ticareti Yetki Belgesi
              <span style="color: #7a7a7a; font-size: 17px">⋇</span>
            </label>
            <select class="form-control" id="exampleFormControlSelect1">
              <option value="" selected>Seçiniz</option>
              <option>Var</option>
              <option>Yok</option>
            </select>
          </div>
        </div>
        <div class="col-6 mt-4">
          <b-form-group class="add-style">
            <label  for="yetkiBelgeNo"
              >Yetki Belge Numarası
              
            </label>
            <b-form-input
              type="text"
              id="yetkiBelgeNo"
              required
              value="3409221"
            />
          </b-form-group>
        </div>
        <div class="col-6 mt-4">
          <b-form-group class="add-style">
            <label  for="yetkiBelgeNo"
              >Düzenleme Tarihi
              
            </label>
            <b-form-input
              type="date"
              id="duzenlemeTarih"
              required
              value="2023-03-06"
            />
          </b-form-group>
        </div>
        <div class="col-12 mt-4">
          <b-form-group class="add-style">
            <label  for="yetkiBelgeNo"
              >Site Üyelik Tarihi
              
            </label>
            <b-form-input
              type="date"
              id="duzenlemeTarih"
              required
              value="2023-04-03"
            />
          </b-form-group>
        </div>
        <div class="col-12 mt-4">
          <b-form-group class="add-style">
            <label  for="ısTelefonu"
              >İş Telefonu
              
            </label>
            <b-form-input
              type="text"
              id="ısTelefonu"
              required
              value="(216) 706-50-38"
            />
          </b-form-group>
        </div>
        <div class="col-12 mt-4">
          <b-form-group class="add-style">
            <label  for="cepTelefonu"
              >Cep Telefonu
              
            </label>
            <b-form-input
              type="text"
              id="cepTelefonu"
              required
              value="(538) 449-91-01"
            />
          </b-form-group>
        </div>
        <div class="col-12 mt-4">
          <b-form-group class="add-style">
            <label  for="ePosta"
              >E-posta Adresi
              
            </label>
            <b-form-input
              type="email"
              id="ePosta"
              required
              value="bilgi@bankamdan.com"
            />
          </b-form-group>
        </div>
        <div class="col-12 mt-4">
          <b-form-group class="add-style">
            <label  for="ısAdresi"
              >E-posta Adresi
              
            </label>
            <b-form-input
              type="text"
              id="ısAdresi"
              required
              maxlength="227"
              value="KÜÇÜKBAKKALKÖY MAH. MOR ORKİDE SK. NO: 3 İÇ KAPI NO: 3 ATAŞEHİR/ İSTANBUL"
            />
          </b-form-group>
        </div>
        <button class="mx-auto saveButton mt-3">Kaydet</button>
      </div>
    </Card>
    <div class="col-4 h-100">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Son Kayıtlar" active>
            <b-card-text></b-card-text>
          </b-tab>
          <b-tab title="Son Erişilenler">
            <b-card-text></b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Firma Kartı", route: "/manage/company" },
    ]);
  },
};
</script>


